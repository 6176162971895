.stack {
  display: flex;
  flex-direction: column;
}

.stack > * + * {
  margin-top: var(--space);
}

.stack__item--tight,
.stack__item--tight + * {
  margin-top: calc(1 * var(--space-xs));
}

.stack__item--tight:first-child {
  margin-top: calc(-1 * var(--space-xs));
}

.stack__item--expando {
  flex: 1;
}
