.stack {
  flex-direction: column;
  display: flex;
}

.stack > * + * {
  margin-top: var(--space);
}

.stack__item--tight, .stack__item--tight + * {
  margin-top: calc(1 * var(--space-xs));
}

.stack__item--tight:first-child {
  margin-top: calc(-1 * var(--space-xs));
}

.stack__item--expando {
  flex: 1;
}
/*# sourceMappingURL=base.647ae017.css.map */
